import React from "react";
import styled from "styled-components";
import videoURL from "../images/EmployerAnimation.mp4";

const StyledWrapper = styled.div`
  .employer-section {
    padding-top: 30px;
    background-color: #ffffff;
  }
  .header {
    text-align: center;
  }
  .video-animation {
    width: 100%;
    text-align: center;
    display: flex;
    margin: 0 auto;
    transform: scale(0.8);
  }
`;

const EmployerHeader = () => {
  return (
    <StyledWrapper>
      <div className="employer-section">
        <h1 className="header">REALrating for employers</h1>
        <video
          id="Test_Video"
          className="video-animation"
          width="400"
          autoPlay
          muted
        >
          <source src={videoURL} type="video/mp4" />
          Your Browser does not support HTML video.
        </video>
      </div>
    </StyledWrapper>
  );
};

export default EmployerHeader;
