import React from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import capitalizeFirstLetter from "../utils/capital";
import ReactMarkdown from "react-markdown";
const EmployeFeatures = ({ Work, ways, link }) => {
  const mainUrl = process.env.GATSBY_API_URL;
  return (
    <StyleWrapper>
      <div className="container-fluid py-5 white-background">
        <div className="feature padding-left-8 padding-right-8">
          <h1 className="title">{Work.title}</h1>
        </div>
        <div className="row padding-left-8 padding-right-8 pb-5 pt-5">
          {Work.RealWorkTypes.length > 0 &&
            Work.RealWorkTypes.map(item => (
              <div className="col-sm-4 col-12 flex-direction-column py-sm-4 py-3">
                <div className="realWorkLogo ml-sm-4">
                  {item.logo && (
                    <img className="img-logo" src={mainUrl + item.logo.url} />
                  )}
                </div>
                <p className="subheading text-center pt-4">
                  <strong className="title-header">{item.logoTitle}</strong>
                  <span className="title-desc text-muted">
                    {" "}
                    <ReactMarkdown cla children={item.logoDescription} />
                  </span>
                </p>
              </div>
            ))}
        </div>
        <div
          className={`padding-right-8 padding-left-8 d-flex justify-content-center " + ${
            ways === "ways" ? "d-none" : "d-block"
          }`}
        >
          {/* <button className="flatButton btn btn-default">Contact us</button> */}
          <a
            rel="noopener"
            rel="noreferrer"
            target="_blank"
            href={link}
            className="flatButton btn btn-default"
          >
            Request Demo
          </a>
        </div>
      </div>
    </StyleWrapper>
  );
};

export default EmployeFeatures;

const StyleWrapper = styled.div`
  .title-desc {
    margin-top: 1rem !important;
  }
  .feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .subtext,
  .subheading {
    text-align: center;
  }

  .realWorkLogo {
    display: flex;
    justify-content: center;
  }
  .img-logo {
    margin: 0 auto;
  }
  .realWorkLogo > img {
    height: 60px;
    object-fit: cover;
  }

  .subheading {
    padding: 0px 15px;
    margin-bottom: 0px;
  }

  .flatButton {
    background: #041e2c;
    color: white;
    padding: 18px;
  }

  .subheading {
    font-size: 14px;
  }
  .img-logo {
    height: 8rem !important;
  }
  .title-header {
    font-size: 19px;
  }
  @media (min-width: 991px) {
    .subtext {
      width: 40%;
      text-align: center;
    }

    .title {
      padding-bottom: 20px;
    }

    .realWorkLogo {
      display: flex;
      justify-content: unset;
    }

    .realWorkLogo > img {
      height: 80px;
      object-fit: cover;
      padding-left: 20px;
    }

    .subheading {
      font-size: 16px;
      text-align: unset;
    }
  }

  @media screen and (max-width: 900px) {
    .img-logo {
      height: 80px !important;
    }
  }
`;
