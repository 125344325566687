import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import styled from "styled-components";

const EmployerForm = () => {
  const { register, handleSubmit, reset } = useForm({});
  const [disableBtn, setDisableBtn] = useState(false);
  const [error, setError] = useState("");

  const submitData = data => {
    setDisableBtn(true);
    reset();
  };
  return (
    <StyleWrapper className="py-5 padding-left-8 padding-right-8">
      <h1 className="title pb-2">Contact us</h1>
      <Form onSubmit={handleSubmit(submitData)}>
        <div className="row">
          <div className="col-sm-6 col-12">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              {...register("name", { required: true })}
              placeholder="John Doe"
            />
          </div>
          <div className="col-sm-6 col-12">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              name="company_name"
              {...register("company_name", { required: true })}
              placeholder="Company"
            />
          </div>
          <div className="col-sm-6 col-12">
            <Form.Label>Job Title</Form.Label>
            <Form.Control
              type="text"
              name="job_title"
              {...register("job_title", { required: true })}
              placeholder="Recruiter"
            />
          </div>
          <div className="col-sm-6 col-12">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              {...register("email", { required: true })}
              placeholder="johndoe@email.co"
            />
          </div>
        </div>
        <Button className="flatButton mt-5" disabled={disableBtn} type="submit">
          Submit
        </Button>
        {error != "" ? <p className="fail-text">{error}</p> : <></>}
      </Form>
    </StyleWrapper>
  );
};

export default EmployerForm;

const StyleWrapper = styled.div`
  width: 100%;
  overflow: scroll;
  @media (max-width: 600px) {
    width: 95%;
    padding-top: 8%;
    padding-bottom: 8%;
  }
  .form-control {
    height: 49px;
    width: 90%;
    background-color: #ececec;
    @media (max-width: 600px) {
      height: 50px;
      width: 100%;
    }
  }

  .flatButton {
    background: #e43d5a;
    color: white;
    padding: 10px 30px !important;
    border: none;
  }

  .form-label {
    font-family: "Nunito Sans";
    font-size: 21px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: 20px;
    @media (max-width: 600px) {
      font-size: 15px;
      line-height: normal;
    }
  }
  .fail-text {
    padding-top: 10px;
    padding-right: 80px;
    font-weight: bolder;
    color: #e43d5a;
  }
`;
