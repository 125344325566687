import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import carouselArrowLeft from "../images/carousel-left-arrow.svg";
import carouselArrowRight from "../images/carousel-right-arrow.svg";

const StyledWrapper = styled.div`
  // Carousel Css

  .slider-slick {
    max-width: ${props => props.theme["percent-60"]};
    margin: auto;
    max-width: 95%;
    @media (max-width: 600px) {
      max-width: 100%;
    }
  }

  .slick-arrow {
    width: 72px;
    height: 72px;
  }

  .slick-next:before,
  .slick-prev:before {
    color: ${props => props.theme["borderBottomColor"]};
    font-size: ${props => props.theme["pixel-30"]};
    font-weight: bolder;
    line-height: 0.5;
  }

  .slick-prev {
    left: -3.7%;
    z-index: 1;
    background-color: #e43d5a !important;
  }

  .slick-next {
    right: -3.7%;
    z-index: 1;
    background-color: #e43d5a !important;
  }

  // .slick-next:hover, .slick-prev:hover {
  //   background-color: #E43D5A !important;
  // }

  // .testimonial-onhover-arrows:hover .slick-arrow {
  //     display: block !important;
  // }

  .slick-prev:before {
    content: url(${carouselArrowLeft});
    transform: rotate(180deg);
  }

  .slick-next:before {
    content: url(${carouselArrowRight});
  }

  .background-color {
    background-color: #304563;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .testimonial {
    color: #ffffff;
    font-size: 24px;
    font-weight: lighter;
    letter-spacing: 0;
    line-height: 42px;

    @media (max-width: 600px) {
      font-size: 14px;
      line-height: normal;
    }
  }

  .author {
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    @media (max-width: 600px) {
      font-size: 15px;
      line-height: normal;
    }
  }

  .testimonial-image {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
`;

const Testimonial = ({ carouselData }) => {
  const mainUrl = process.env.GATSBY_API_URL;
  var settings = {
    speed: 500,
    slidesToShow: 1,
    infinity: true,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <StyledWrapper className="margin-top-100">
      <div className="container-fluid testimonial-onhover-arrows pb-5">
        {/* <h1 className="padding-left-8 mb-4">test</h1> */}
        <Slider {...settings} className="slider-slick">
          {carouselData.length > 0 &&
            React.Children.toArray(
              carouselData.map(item => (
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 col-md-4 p-0 boxHeight">
                      {item.image && (
                        <img
                          className="img-fluid testimonial-image"
                          src={mainUrl + item.image.url}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="col-12 col-md-8 background-color padding-left-8 padding-right-8 boxHeight">
                      <p className="testimonial"> {item.description} </p>
                      <p className="author pt-4"> {item.author} </p>
                    </div>
                  </div>
                </div>
              ))
            )}
        </Slider>
      </div>
    </StyledWrapper>
  );
};

export default Testimonial;
