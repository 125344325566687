import React from "react";
import Layout from "../components/layout";
import Testimonial from "../components/Testimonial";
import EmployeFeatures from "../components/EmployeFeatures";
import EmployerForm from "../components/EmployerForm";
import LogosBlock from "../components/LogosBlock";
import SEO from "../components/seo";
import axios from "axios";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Loader";
import EmployerHeader from "../components/EmployerHeader";
const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Employers />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/real-employerpage`);
};
const fetchSponsorImage = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/real-homepage`);
};
const Employers = () => {
  const [newData, setNewData] = useState(null);
  const [realRatingPartners, setRealRatingPartners] = useState(null);
  const { data: pageData } = useQuery("Employers", fetchData);
  const { data: Logos } = useQuery("Logos", fetchSponsorImage);
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
    if (Logos !== undefined) {
      setRealRatingPartners(Logos.data);
    }
  }, [pageData, Logos]);
  return (
    <Layout>
      {newData === null && <Loader />}
      {newData && <SEO title={newData.SEOtitle} />}
      {newData && <EmployerHeader />}

      {newData && newData.showEmployerWorks && newData.EmployerWorks && (
        <EmployeFeatures
          Work={newData.EmployerWorks}
          link={newData.RequestDemoLink}
        />
      )}
      {newData &&
        newData.showCarousel &&
        newData.EmployerCarousel.length > 0 && (
          <Testimonial carouselData={newData.EmployerCarousel} />
        )}

      {realRatingPartners &&
        realRatingPartners.showRealPartners &&
        realRatingPartners.RealPartners && (
          <LogosBlock LogoSection={realRatingPartners.RealPartners} />
        )}
      {/* {pageData.showWaystoUse && <EmployeFeatures Work={pageData.WaystoUse} ways="ways"/>} */}
      <div id="contactUs">
        {newData && newData.showEmployerForm && <EmployerForm />}
      </div>
    </Layout>
  );
};
