import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

const LogosBlock = ({ LogoSection }) => {
  const { title } = LogoSection;
  const mainUrl = process.env.GATSBY_API_URL;
  const first = title.split(" ");
  const text = first[0] + " " + first[1].toLowerCase();

  return (
    <StyleWrapper>
      <div className="container-fluid">
        <h1 className="text-center mb-4 padding-left-8 padding-right-8 logo-line-height">
          {text}
        </h1>
        {LogoSection.description && (
          <p className="subtext text-center logo-description d-block mb-4 react-desc-html">
            <ReactMarkdown source={LogoSection.description} />
          </p>
        )}
        <div className="row padding-left-8 padding-right-8 animated">
          {LogoSection.logo.length > 0 &&
            React.Children.toArray(
              LogoSection.logo.map(item => (
                <div
                  className="col-4 col-md-2 bouncyJack"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  data-aos-offset="500"
                >
                  {item.image && (
                    <img
                      className="img-fluid d-block logo-images"
                      src={mainUrl + item.image.url}
                      alt=""
                    />
                  )}
                </div>
              ))
            )}
        </div>
      </div>
    </StyleWrapper>
  );
};

export default LogosBlock;

const StyleWrapper = styled.div`
  background-color: white;
  padding: 50px 0px;

  .logo-line-height {
    max-width: 70%;
    width: 100%;
    margin: auto;
    @media (max-width: 600px) {
      line-height: 30px;
      max-width: 100%;
    }
  }

  .logo-description {
    max-width: 800px;
  }

  .logo-images {
    padding: 5px;
    height: 100%;
    max-height: 50px;
    object-fit: contain;
    margin: 25px auto 25px auto;
  }

  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: flex;
    justify-content: center;
  }

  .bouncyJack {
    animation-name: floatBubble;
    animation-duration: 0.75s;
    animation-delay: 2s;
  }

  .react-desc-html a {
    text-decoration: underline;
    color: black;
  }

  @keyframes floatBubble {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
      opacity: 1;
      transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
      transform: scale3d(0.8, 0.8, 0.8);
    }

    60% {
      transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
      transform: scale3d(0.95, 0.95, 0.95);
    }

    to {
      transform: scale3d(1, 1, 1);
    }
  }
`;
